import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";

const GroupWise = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);
  // States hook here
  const [amountChart, setAmountChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: _t(t("Amount")),
        data: [],
      },
    ],
  });

  //all data
  const [reportData, setReportData] = useState(null);

  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);

  //useEffect == componentDidMount()
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //get GroupWise reports filter
  const getGroupWiseSelected = () => {
    if (startDate !== null && endDate !== null) {
      setLoading(true);
      var fromDate = startDate.toISOString();
      var toDate = endDate.toISOString();
      const url = BASE_URL + "/settings/get-food-group-report";
      let formData = {
        fromDate,
        toDate,
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          let formattedAmount = res.data[1].map((item) =>
            parseFloat(formatPrice(item))
          );
          setAmountChart({
            ...amountChart,
            options: {
              ...amountChart.options,
              xaxis: { ...amountChart.options.xaxis, categories: res.data[0] },
            },
            series: [
              { name: amountChart.series[0].name, data: formattedAmount },
            ],
          });
          handleFoodGroupBy(res.data[2]);
          // setReportData(res.data[2]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select all the field to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const handleFoodGroupBy = (foodData) => {
    if (foodData && Array.isArray(foodData)) {
      const grouped = groupBy(foodData, "food_group");

      setReportData(Object.keys(grouped).length != 0 ? grouped : null);
      setGeneratedReport(Object.keys(grouped).length != 0 ? true : false);
    } else {
      setReportData(null);
      setGeneratedReport(false);
    }
  };

  const groupBy = (array, key) => {
    return array.reduce(function (result, currentValue) {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };



//  start Datepicker
const [isOpen, setIsOpen] = useState(false);
const handleStartDateChange = (e) => {
  setIsOpen(!isOpen);
  setStartDate(e);
};
const handleStartDateClick = (e) => {
  e.preventDefault();
  setIsOpen(!isOpen);
};

// End Datepicker

const [isEndOpen, setIsEndOpen] = useState(false);
const handleEndDateChange = (e) => {
  setIsEndOpen(!isEndOpen);
  setEndDate(e);
};
const handleEndDateClick = (e) => {
  e.preventDefault();
  setIsEndOpen(!isEndOpen);
};



  return (
    <>
      <Helmet>
        <title>{_t(t("Group wise report"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Food Group Wise report"))}
                                </span>  
                              </li>
                            </ul>
                          </div>
                        </div>
                       
                       

 {/* date picker filter area */}
<div className="row gx-2 mt-2">
<div className="col-12 d-md-block-dtpicker">
  <button
    className="btn btn-success d-rj-dtpicker-from-ctrl"
    onClick={handleStartDateClick}
  >
    {startDate == null
      ? "Start Date"
      : moment(startDate).format("DD-MM-YYYY")}
  </button>
  {isOpen && (
    <DatePicker
      selected={startDate}
      onChange={handleStartDateChange}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className="form-control sm-text py-2 t-mb-15 mb-md-0"
      placeholderText={_t(t("From date"))}
      shouldCloseOnSelect={true}
      inline
      withPortal
    />
  )}

  <button
    className="btn btn-success d-rj-dtpicker-from-ctrl"
    onClick={handleEndDateClick}
  >
    {endDate == null
      ? "End Date "
      : moment(endDate).format("DD-MM-YYYY")}
  </button>
  {isEndOpen && (
    <DatePicker
      selected={endDate}
      onChange={handleEndDateChange}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className="form-control sm-text py-2 t-mb-15 mb-md-0"
      placeholderText={_t(t("To date"))}
      shouldCloseOnSelect={true}
      inline
      withPortal
    />
  )}
  <button
    className="btn  btn-primary text-uppercase sm-text py-2"
    onClick={getGroupWiseSelected}
  >
    {_t(t("Generate"))}
  </button>                       
</div>
</div>

 {/* date picker filter area */}


                        {generatedReport ? (
                          <>
                            <div className="row gx-2 justify-content-center t-pt-15">
                              <div className="col-12 mb-md-0">
                                <Chart
                                  options={amountChart.options}
                                  series={amountChart.series}
                                  type="bar"
                                  width="100%"
                                  height="350px"
                                />
                              </div>
                            </div>
                            {/* loop here*/}
                            {reportData !== null &&
                              reportData !== undefined &&
                              Object.keys(reportData).length != 0 &&
                              Object.keys(reportData).map(
                                (lol, primaryIndex) => {
                                  return (
                                    <div
                                      key={primaryIndex}
                                      className="row gx-2 justify-content-center t-pb-15 t-pt-15"
                                    >
                                      <h4>{lol}</h4>
                                      <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                                        <table className="table table-bordered table-hover min-table-height mt-3">
                                          <thead className="align-middle">
                                            <tr>
                                              <th
                                                scope="col"
                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                                {_t(t("S/L"))}
                                              </th>
                                              <th
                                                scope="col"
                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                                {_t(t("Name"))}
                                              </th>
                                              {/* <th
                                                scope="col"
                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                                {_t(t("Group"))}
                                              </th>
                                              <th
                                                scope="col"
                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                                {_t(t("Variation"))}
                                              </th> */}
                                              <th
                                                scope="col"
                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                                {_t(t("Qty"))}
                                              </th>
                                              <th
                                                scope="col"
                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                                {_t(t("Bill"))}
                                              </th>
                                              <th
                                                scope="col"
                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                              >
                                                {_t(t("Date"))}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="align-middle">
                                            {Array.isArray(
                                              Object.values(reportData)[
                                                primaryIndex
                                              ]
                                            ) &&
                                              Object.values(reportData)[
                                                primaryIndex
                                              ].length != 0 &&
                                              Object.values(reportData)[
                                                primaryIndex
                                              ].map((item, secondaryIndex) => {
                                                return (
                                                  <tr
                                                    key={secondaryIndex}
                                                    className="align-middle"
                                                  >
                                                    <th
                                                      scope="row"
                                                      className="xsm-text text-capitalize align-middle text-center"
                                                    >
                                                      {secondaryIndex + 1}
                                                    </th>

                                                    <td className="xsm-text align-middle text-center">
                                                      {item.food_item}
                                                    </td>

                                                    {/* <td className="xsm-text align-middle text-center">
                                                      {item.food_group}
                                                    </td>

                                                    <td className="xsm-text align-middle text-center">
                                                      {item.variation !== null
                                                        ? item.variation
                                                        : "-"}
                                                    </td> */}

                                                    <td className="xsm-text align-middle text-center">
                                                      {item.quantity}
                                                    </td>

                                                    <td className="xsm-text align-middle text-center">
                                                      {currencySymbolLeft()}
                                                      {formatPrice(item.price)}
                                                      {currencySymbolRight()}
                                                    </td>
                                                    <td className="xsm-text align-middle text-center">
                                                      <Moment format="LL">
                                                        {item.created_at}
                                                      </Moment>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </>
                        ) : (
                          <div className="row gx-2 justify-content-center t-pt-15">
                            <div className="col-8 mt-5 py-4 mb-md-0 card text-center text-uppercase sm-text">
                              {_t(
                                t("Generate report following the above field")
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default GroupWise;
