import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";


const SalesReports = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);
  // States hook here
  const [amountChart, setAmountChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: _t(t("Amount")),
        data: [],
      },
    ],
  });

  //all data
  const [reportData, setReportData] = useState(null);
  const [total, setTotal] = useState(null);
  const [offlineTotal, setOfflineTotal] = useState(null);
  const [onlineTotal, setOnlineTotal] = useState(null);

  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);

  //useEffect == componentDidMount()
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //get GroupWise reports filter
  const getGroupWiseSelected = () => {
    if (startDate !== null && endDate !== null) {
      setLoading(true);
      const formData = new FormData();
      const start = moment(startDate).format("YYYY-MM-DD");
      const end = moment(endDate).format("YYYY-MM-DD");

      startDate && formData.append("from", start);
      endDate && formData.append("to", end);

      const url = BASE_URL + "/settings/SalesTransactionView";

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setReportData(res.data.data);
          let sales = res.data.data.sales_report;
          let report = res.data.data;
          const a = sales.reduce(
            (total, num) => Number(total) + Number(num.total_quantity),
            0
          );
          const b = sales.reduce(
            (total, num) => Number(total) + Number(num.paid_amount),
            0
          );
          if (report.offline_report.length > 0) {
            const c = report.offline_report.reduce(
              (total, num) => Number(total) + Number(num.quantity),
              0
            );
            const d = report.offline_report.reduce(
              (total, num) => Number(total) + Number(num.amount),
              0
            );
            setOfflineTotal({ qty: c, amount: d });
          }

          if (report.online_report.length > 0) {
            const e = report.online_report.reduce(
              (total, num) => Number(total) + Number(num.quantity),
              0
            );
            const f = report.online_report.reduce(
              (total, num) => Number(total) + Number(num.amount),
              0
            );
            setOnlineTotal({ qty: e, amount: f });
          }

          setTotal({ qty: a, amount: b });
          setGeneratedReport(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select all the field to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const findMode = (str) => {
    const string = JSON.parse(str);
    const offline = ["Cash", "GooglePay", "Phonepe"];
    const res = offline.includes(string) ? "Offline" : "Online";
    return res;
  };



  
//  start Datepicker
const [isOpen, setIsOpen] = useState(false);
const handleStartDateChange = (e) => {
  setIsOpen(!isOpen);
  setStartDate(e);
};
const handleStartDateClick = (e) => {
  e.preventDefault();
  setIsOpen(!isOpen);
};

// End Datepicker

const [isEndOpen, setIsEndOpen] = useState(false);
const handleEndDateChange = (e) => {
  setIsEndOpen(!isEndOpen);
  setEndDate(e);
};
const handleEndDateClick = (e) => {
  e.preventDefault();
  setIsEndOpen(!isEndOpen);
};



const findPaymentType = (item) => {

if(item.is_settled == 0){
  return 'Unsettled';
} else if (item.bill_distribution) {

  var paymentDetails =  JSON.parse(
    item.bill_distribution  
  );
  
 return Array.isArray(paymentDetails) ? paymentDetails.map(function(i){
    return i.payment_type;
}).join(",") : 'Unknown'; 

} else {
  return 'Unknown';
}
 

}


  return (
    <>
      <Helmet>
        <title>{_t(t("Sales Report"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Sales Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>



 {/* date picker filter area */}
 <div className="row gx-2 mt-2">
<div className="col-12 d-md-block-dtpicker">
  <button
    className="btn btn-success d-rj-dtpicker-from-ctrl"
    onClick={handleStartDateClick}
  >
    {startDate == null
      ? "Start Date"
      : moment(startDate).format("DD-MM-YYYY")}
  </button>
  {isOpen && (
    <DatePicker
      selected={startDate}
      onChange={handleStartDateChange}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className="form-control sm-text py-2 t-mb-15 mb-md-0"
      placeholderText={_t(t("From date"))}
      shouldCloseOnSelect={true}
      inline
      withPortal
    />
  )}

  <button
    className="btn btn-success d-rj-dtpicker-from-ctrl"
    onClick={handleEndDateClick}
  >
    {endDate == null
      ? "End Date "
      : moment(endDate).format("DD-MM-YYYY")}
  </button>
  {isEndOpen && (
    <DatePicker
      selected={endDate}
      onChange={handleEndDateChange}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className="form-control sm-text py-2 t-mb-15 mb-md-0"
      placeholderText={_t(t("To date"))}
      shouldCloseOnSelect={true}
      inline
      withPortal
    />
  )}

  
  <button
    className="btn  btn-primary text-uppercase sm-text py-2"
    onClick={getGroupWiseSelected}
  >
    {_t(t("Generate"))}
  </button>                       
</div>
</div>

 {/* date picker filter area */}




                       
                        {generatedReport ? (
                          <>
                            {reportData !== null &&
                            reportData !== undefined &&
                            reportData.sales_report.length > 0 ? (
                              <div className="row gx-2 justify-content-center t-pb-15 t-pt-15">
                                <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                                  <table className="table table-bordered table-hover min-table-height mt-3">
                                    <thead className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("S.No"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Date"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Particulars"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Qty"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Online/Offline"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Sales Mode"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Amount"))}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                      {/* loop here*/}
                                      {reportData.sales_report.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index + 1}
                                              </th>

                                              <td className="xsm-text align-middle text-center">
                                                {moment(item.created_at).format(
                                                  "MMM DD, YYYY"
                                                )}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                ORD - {item.id}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.total_quantity !== null
                                                  ? item.total_quantity
                                                  : "-"}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                { item.is_settled == 0 ? 'Offline' : findMode(item.payment_type)}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                 { findPaymentType(item)                                                 
                                                  }
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                             
                                                {currencySymbolLeft()}
                                                {". "}
                                                {formatPrice( item.is_settled == 0 ? item.total_payable : item.paid_amount)}
                                                {currencySymbolRight()}
                                              
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                    <tfoot className="align-middle">
                                      <tr className="align-middle">
                                        <th
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        ></th>

                                        <td className="xsm-text align-middle text-center"></td>

                                        <td className="xsm-text align-middle text-center">
                                          Total
                                        </td>

                                        <td className="xsm-text align-middle text-center">
                                          {total.qty}
                                        </td>

                                        <td className="xsm-text align-middle text-center"></td>

                                        <td className="xsm-text align-middle text-center"></td>

                                        <td className="xsm-text align-middle text-center">
                                        <b> {currencySymbolLeft()}
                                          {". "}
                                          {formatPrice(total.amount)}
                                          {currencySymbolRight()}
                                          </b>
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>

                                {/* Offline Summary *************************************************** */}
                                <div className="col-6 t-mb-15 mb-md-0 table-responsive">
                                  <span>Offline Summary</span>
                                  <table className="table table-bordered table-hover min-table-height mt-3">
                                    <thead className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("S.No"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Mode"))}
                                        </th>
                                    
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Total Amount"))}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                      {/* loop here*/}
                                      {reportData.offline_report.length !==
                                      0 ? (
                                        reportData.offline_report.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index + 1}
                                                </th>

                                                <td className="xsm-text align-middle text-center">
                                                  {
                                                    item.payment_type
                                                  }
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                 {currencySymbolLeft()}
                                                  {". "}
                                                  {formatPrice(item.amount)}
                                                  {currencySymbolRight()}
                                                
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <>
                                          <tr className="align-middle">
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              -
                                            </th>

                                            <td className="xsm-text align-middle text-center">
                                              -
                                            </td>

                                            <td className="xsm-text align-middle text-center">
                                              -
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </tbody>
                                    <tfoot className="align-middle">
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          -
                                        </td>

                                        <td className="xsm-text align-middle text-center">
                                          Total
                                        </td>
                                      

                                        {offlineTotal ? (
                                          <td className="xsm-text align-middle text-center">
                                          <b>  {currencySymbolLeft()}
                                            {". "}
                                            {formatPrice(offlineTotal.amount)}
                                            {currencySymbolRight()}
                                            </b>
                                          </td>
                                        ) : (
                                          <td className="xsm-text align-middle text-center">
                                            -
                                          </td>
                                        )}
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>

{/* Offline Summary *************************************************** */}


{/* Online Summary *************************************************** */}
                                <div className="col-6 t-mb-15 mb-md-0 table-responsive">
                                  <span>Online Summary</span>
                                  <table className="table table-bordered table-hover min-table-height mt-3">
                                    <thead className="align-middle">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("S.No"))}
                                        </th>
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Mode"))}
                                        </th>
                                   
                                        <th
                                          scope="col"
                                          className="sm-text text-capitalize align-middle text-center border-1 border"
                                        >
                                          {_t(t("Total Amount"))}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                      {/* loop here*/}
                                      {reportData.online_report.length !== 0 ? (
                                        reportData.online_report.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index + 1}
                                                </th>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.payment_type}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {currencySymbolLeft()}
                                                  {". "}
                                                  {formatPrice(item.amount)}
                                                  {currencySymbolRight()}
                                               
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <>
                                          <tr className="align-middle">
                                            <th
                                              scope="row"
                                              className="xsm-text text-capitalize align-middle text-center"
                                            >
                                              -
                                            </th>

                                            <td className="xsm-text align-middle text-center">
                                              -
                                            </td>

                                      
                                            <td className="xsm-text align-middle text-center">
                                              -
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </tbody>
                                    <tfoot className="align-middle">
                                      <tr className="align-middle">
                                        <th
                                          scope="row"
                                          className="xsm-text text-capitalize align-middle text-center"
                                        >
                                          -
                                        </th>

                                        <td className="xsm-text align-middle text-center">
                                          Total
                                        </td>

                                      
                                        {onlineTotal ? (
                                          <td className="xsm-text align-middle text-center">
                                          <b>  {currencySymbolLeft()}
                                            {". "}
                                            {formatPrice(onlineTotal.amount)}
                                            {currencySymbolRight()}
                                            </b>
                                          </td>
                                        ) : (
                                          <td className="xsm-text align-middle text-center">
                                            -
                                          </td>
                                        )}
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>

{/* Online Summary *************************************************** */}


                              </div>
                            ) : (
                              <div className="row gx-2 justify-content-center t-pt-15">
                                <div className="col-8 mt-5 py-4 mb-md-0 card text-center text-uppercase sm-text">
                                  {_t(
                                    t(
                                      "Generate report following the above field"
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="row gx-2 justify-content-center t-pt-15">
                            <div className="col-8 mt-5 py-4 mb-md-0 card text-center text-uppercase sm-text">
                              {_t(
                                t("Generate report following the above field")
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default SalesReports;
